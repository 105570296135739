<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <span class="primary--text display-2">Admision rapida</span><br>

        </v-col>
        <v-col cols="auto" md="12">
            <v-card>
                <v-card-title>
                    <span class="black--text headline">Registrar ingreso</span>
                    <div class="flex-grow-1"></div>
                    <v-btn text class="info" dark :to="{name:'Admision'}">
                        <v-icon>mdi-chevron-left</v-icon>
                        regresar
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row class="grey lighten-3" v-if="paciente">
                            <v-col cols="12" sm="12" md="3">
                                <v-icon color="info" style="font-size:200px;">mdi-account-check</v-icon>

                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <v-row>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">NOMBRE: </label>
                                        {{paciente.tblConfPersonasnaturales[0].copnNombre1 + ' ' + paciente.tblConfPersonasnaturales[0].copnNombre2 + ' ' + paciente.tblConfPersonasnaturales[0].copnApellido1 + ' ' + paciente.tblConfPersonasnaturales[0].copnApellido2}}

                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">IDENTIFICACION: </label>
                                        {{paciente.tipoidentificacion.comaNombrecorto +' - '+ paciente.copeIdentificacion}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">EDAD: </label>
                                        {{ calcularEdad(paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento)}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">SEXO: </label>
                                        {{paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrelargo}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">PAIS: </label>
                                        {{paciente.pais.comaNombrelargo}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">DEPARTAMENTO: </label>
                                        {{paciente.departamento.comaNombrelargo}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">MUNICIPIO: </label>
                                        {{paciente.municipio.comaNombrelargo}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">BARRIO: </label>
                                        {{paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">DIRECCION: </label>
                                        {{paciente.copeDireccion}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">TELEFONO: </label>
                                        {{paciente.copeTelefono}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" v-if="citas.length > 0 ">
                                        <label class="primary--text">EPS: </label>
                                        <template v-if="citas[0].tblConfBasedatospacientes.length > 0">
                                            {{ citas[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre }}
                                        </template>
                                        <template v-else-if="citas[0].cocoId">
                                            {{ citas[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre }}
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row class="grey lighten-3" v-if="citas.length > 0 ">
                            <v-col cols="12" sm="12" md="12">
                                <v-card>
                                    <v-card-text>
                                        <v-row no-gutters v-for="(item,i) in citas" :key="i">
                                            <v-col cols="10" sm="10" md="10">
                                                <v-row>

                                                    <v-col cols="12" sm="12" md="3">
                                                        {{(item.citaTipo==0)?'CITA':(item.citaTipo==1)?'LABORATORIO':(item.citaTipo==2)?'PROCEDIMIENTO':(item.citaTipo==3)?'ORDEN DE SERVICIO':''}} CON FECHA <span class="primary--text">{{item.citaFecha}}</span>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="3" v-if="item.coprId">
                                                        EMPLEADO <span class="primary--text">{{item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 +  " "+item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1}}</span>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="2" v-if="item.coprId && item.citaTipo == 0">
                                                        HORA <span class="primary--text">{{item.citaHora}}</span>
                                                    </v-col>
                                                    
                                                    <v-col cols="12" sm="12" md="12" v-if="item.citaTipo == 0">
                                                        SERVICIO <span class="primary--text">{{item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}}</span>

                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" v-if="item.citaTipo == 2">
                                                        CUPS <span class="primary--text">{{item.tblConfCup.cocuNombre }}</span>
                                                    </v-col>

                                                </v-row>
                                                <v-divider></v-divider>
                                            </v-col>
                                            <v-col cols="auto" md="2" class="pl-10 pt-5">
                                                <v-tooltip top v-if="item.facturado">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined dense color="info" :loading="loading" class="mr-2" v-on="on" @click="Gpdf(item)">
                                                            <v-icon>mdi-file-pdf-box</v-icon> PDF
                                                        </v-btn>
                                                    </template>
                                                    <span>Generar factura</span>
                                                </v-tooltip>
                                                <v-tooltip top v-if="item.facturado && (item.citaTipo == 1 || item.citaTipo == 0)">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined dense color="orange" :loading="loading" class="mr-2" v-on="on" @click="pdfEntrega(item)">
                                                            <v-icon>mdi-file-pdf-box</v-icon> Ticked
                                                        </v-btn>
                                                    </template>
                                                    <span>Generar ticked</span>
                                                </v-tooltip>
                                                <v-tooltip top v-if="!item.facturado">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined dense v-on="on" color="success" class="mr-2" @click.stop="dialog = true" @click="facturar(item)">
                                                            <v-icon>mdi-account-check</v-icon> Ingreso
                                                        </v-btn>
                                                    </template>
                                                    <span>Registrar llegada</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-card>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text :to="{name:'Admision'}">Finalizar</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <v-form @submit.prevent="agregar" v-if="cita && form">
        <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">Ingresar</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <!-- <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined dense required label="Numero de la factura" :error="$v.form.cifaNumFactura.$error" v-model="form.cifaNumFactura" readonly></v-text-field>
                            </v-col> -->
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined dense label="Numero de autorizacion" v-model="form.cifaNumeroAutorizacion"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" v-if="VERSION == 'patologia'">
                                <v-text-field outlined dense label="Medico remitente" v-model="form.cifaMedicoremitente"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined dense label="Codigo vale" v-model="form.cifaCodigoVale"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined dense label="Copago" v-model="form.cifaCopago"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="3" v-if="form.tipoturno.length > 0 && cita.citaTipo == 0 ">
                                <v-autocomplete outlined dense required v-model="form.cifaTipoSala" :items="form.tiposala" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Sala" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="3" v-if="form.tipoturno.length > 0 && cita.citaTipo == 0 ">
                                <v-autocomplete outlined dense required v-model="form.cifaTipoTurno" :items="form.tipoturno" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Turno" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="cita.citaTipo==1 && cita.citaTipo==2">
                                <v-autocomplete outlined dense required v-model="form.cifaTipoFinalidad" :items="tipofinalidad" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de finalidad" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" v-if="cita.citaTipo==1">
                                <v-menu v-model="form.menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field outlined dense v-model="form.cifaFechareclamo" label="Fecha de reclamo" prepend-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES"  v-model="form.cifaFechareclamo" @input="form.menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="cita.citaTipo==0">
                                <v-switch v-model="form.cifaTelemedicina" label="Telemedicina"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="cita.citaTipo==3 ||cita.citaTipo==1">
                            <v-col cols="auto" md="12">
                                <v-row justify="space-around">
                                    <v-col cols="auto" md="12">
                                        <v-toolbar flat>
                                            <v-toolbar-title>Ordenes</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-btn text @click="agregarOrden">Agregar nuevo</v-btn>
                                            <v-divider class="mx-4" inset vertical></v-divider>

                                            <div class="flex-grow-1"></div>
                                        </v-toolbar>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <template v-for="(items,index) in formOrdenes">
                                    <v-row :key="items.ciorId" v-if="formOrdenes.length > 0">
                                        <v-col cols="12" sm="12" md="9" v-if="items.cups">
                                            <v-autocomplete outlined dense required :error="$v.formOrdenes.$each[index].cocuId.$error" v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2" v-if="cita.citaTipo==3">
                                            <v-text-field outlined dense required v-model="items.ciorCantidad" label="Cantidad"></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" md="1" v-if="index > 0">
                                            <v-btn text icon color="error" @click="eliminarOrden(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                        <v-row v-if="cita.citaTipo==4 ||cita.citaTipo==3 ||cita.citaTipo==2 || cita.citaTipo==1 ">
                            <v-col cols="auto" md="12">
                                <v-row justify="space-around">
                                    <v-col cols="auto" md="12">
                                        <v-toolbar flat>
                                            <v-toolbar-title>Diagnosticos</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-btn text @click="agregarDiagnostico">Agregar nuevo</v-btn>
                                            <v-divider class="mx-4" inset vertical></v-divider>

                                            <div class="flex-grow-1"></div>
                                        </v-toolbar>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <template v-for="(items,index) in formDiagnosticos">
                                    <v-row :key="index" v-if="formDiagnosticos.length > 0">
                                        <v-col cols="12" sm="12" md="12" v-if="items.tipocie10">
                                            <v-autocomplete outlined dense required :error="$v.formDiagnosticos.$each[index].cidiTipoCie10.$error" v-model="items.cidiTipoCie10" :loading="items.tipocie10.isLoading" :search-input.sync="items.tipocie10.search" hide-no-data hide-selected no-filter :items="items.tipocie10.tipocie10" @keypress.enter="searchCIE10(items.tipocie10)" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de CIE 10" clearable>
                                                <template v-slot:selection="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>

                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !== 'object')">
                                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                            <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-autocomplete outlined dense :error="$v.formDiagnosticos.$each[index].cidiTipoDiagnostico.$error" required v-model="items.cidiTipoDiagnostico" :items="tipodiagnostico" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Diagnostico" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete outlined dense :error="$v.formDiagnosticos.$each[index].cidiTipoClase.$error" required v-model="items.cidiTipoClase" :items="tipoclasedx" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Clase" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="auto" md="1" v-if="index>0">
                                            <v-btn text icon color="error" @click="eliminarDiagnostico(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" :loading="loadingFactura" dark @click="agregar">Facturar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</v-container>
</template>

<script>

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
    documentos
}
from "../../utils/documentos";
import {
    required
}
from 'vuelidate/lib/validators';
import generales from '../../mixins/generales.js';
export default {
    mixins: [generales],
    data() {
        return {

            url: "cita/facturaciones/",
            loading: false,
            VERSION: this.$cookies.get("TIPO"),
            DEFAULT: this.$cookies.get("DEFAULT"),
            loadingFactura: false,
            paciente: null,
            citas: [],
            cita: null,
            meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],

            form: {
                menu: false,
                menu2: false,
                cifaFecha: new Date().yyyymmdd(),
                cifaMedicoremitente: null,
                cifaCopago: 0,
                cifaCodigoVale: null,
                cocoId: null,
                citaTipoMes: null,
                cifaNumFactura: null,
                citaId: null,
                cifaTipoFinalidad: null,
                cbdpId: null,
                cifaNumeroAutorizacion: null,
                cifaFechareclamo: new Date().yyyymmdd(),
                cifaTelemedicina: 0,

            },
            formDiagnosticos: [],
            formOrdenes: [],
            tipomes: [],
            tipofinalidad: [],
            tipodiagnostico: [],
            tipoordenes: [],
            tipoclasedx: [],
            tipoestado: [],
            datosdian: [],
            tipoestadocita: [],
            tipoprocedimiento: [],

        }
    },
    validations: {
        formDiagnosticos: {
            $each: {

                cidiTipoCie10: {
                    required,
                },

                cidiTipoDiagnostico: {
                    required,
                },
                cidiTipoClase: {
                    required,
                },

            }
        },
        form: {
            cifaFecha: {
                required,
            },

            cifaCopago: {
                required,
            },
            

            citaTipoMes: {
                required,
            },

        },
        formOrdenes: {
            $each: {
                ciorTipoOrden: {
                    required,
                },
                cocuId: {
                    required,
                },
            }
        },
    },
    created: async function () {
        

        try {
            if (this.getPermiso("verAdmisiones")) {
                if (this.$route.params.paciente) {
                    this.paciente = this.$route.params.paciente;
                    this.citas = this.$route.params.citas;
                    this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_HISTORIAS").then(data => data);
                    this.tipomes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMES").then(data => data);

                    this.tipofinalidad = await this.$apiService.index("sistema/maestra/index/TBL_TIPOFINALIDADPRO").then(data => data);
                    // this.tipocie10 = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCIE10").then(data => data);
                    this.tipodiagnostico = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS").then(data => data);
                    this.tipoclasedx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCLASEDX").then(data => data);
                    this.tipodiagnostico = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS").then(data => data);
                    this.tipoclasedx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCLASEDX").then(data => data);
                    this.tipoordenes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSORDENES").then(data => data);
                    this.tipoprocedimiento = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSPROCEDIMIENTOS").then(data => data);
                    this.tiposala = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSALAS").then(data => data);
                }

            }
        } catch (error) {
            console.log(error);
        }

    },
    methods: {

        async searchCup(item) {
            item.cups.isLoading = true;
            if (item.cups.search.length > 0 && item.cups.search.toString().replace(/ /g, "") != "") {
                this.$apiService.index("sistema/cups/buscar/" + item.cups.search.toUpperCase()).then(data => {
                    item.cups.isLoading = false;
                    item.cups.cups = data;

                });
            }

        },
        async searchCIE10(tipocie10) {
            tipocie10.isLoading = true;
            if (tipocie10.search.length > 0 && tipocie10.search.toString().replace(/ /g, "") != "") {
                tipocie10.tipocie10 = await this.$apiService.index(`sistema/maestra/buscar3/TBL_TIPOSCIE10?comaNombrecorto=${tipocie10.search}&comaNombrelargo=${tipocie10.search}`).then(data => data);
            }
            tipocie10.isLoading = false;
        },

        async agregar() {
            // if (this.cita.citaTipo == 1 || this.cita.citaTipo == 3) {
            //     for (let orden of this.formOrdenes) {
            //         orden.ciorTipoOrden = this.tipoordenes.find(e => e.comaNombrecorto == ((this.cita.citaTipo == 1) ? 'TP_ORD_LAB' : 'TP_ORD_SER')).comaId;

            //     }
            // }

            try {
                this.$v.form.$touch();
                this.$v.formDiagnosticos.$touch();
                this.$v.formOrdenes.$touch();
                if (!this.$v.form.$error && !this.$v.formDiagnosticos.$error && !this.$v.formOrdenes.$error) {
                    this.loadingFactura = true;
                    if (this.getPermiso("crearFacturaciones")) {
                        let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
                        if (data) {
                            this.citas.find(e => e.citaId == this.cita.citaId).tblCitaFacturaciones = [];
                            this.citas.find(e => e.citaId == this.cita.citaId).tblCitaFacturaciones.push(data);
                            this.citas.find(e => e.citaId == this.cita.citaId).facturado = true;
                            this.$socket.emit('cambiarturnos', { username: 'turnos',version:this.user.version, mensaje: 'factura' });
                            if ((this.cita.citaTipo == 1 || this.cita.citaTipo == 3) && this.formOrdenes.length > 0) {

                                this.formOrdenes.forEach((d) => d.cifaId = data.cifaId);
                                this.$apiService.create("cita/asistenciales/atender/create/orden" + "?tipo=AUTORIZACION", this.formOrdenes).then(data => data);

                                this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
                                this.$apiService.create("cita/asistenciales/atender/create/diagnostico", this.formDiagnosticos).then(data => data);

                            }
                            if (this.cita.citaTipo == 2 && this.formDiagnosticos.length > 0) {

                                this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
                                this.$apiService.create("cita/asistenciales/atender/create/diagnostico", this.formDiagnosticos).then(data => data);
                            }
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                        }
                    }
                }
                this.close();
            } catch (error) {
                this.loadingFactura = false;
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }
            this.loadingFactura = false;

        },
        async Gpdf(item) {
            try {
                if (this.getPermiso("verFacturaciones")) {
                    this.cita = item;
                    var factura = await this.$apiService.index(this.url + "pdf/" + this.cita.tblCitaFacturaciones[0].cifaId).then(data => data);
                    if (factura) {
                        var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + factura.tblCitaCita.citaId).then(data => data);
                        this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${factura.serialId}`).then(data => data);

                        let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
                        var tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
                        var ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
                        if (typeof window !== 'undefined') {
                            let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,ips, this.$cookies.get("ENTIDAD"));
                            let pdf = new jsPDF();
                            if (this.tipo == 'EVENTO' && parseInt(this.datosdian.find(e => e.comaNombrecorto == 'CANTIDADFACTURAS').comaValor)> 1) {
                                let rep = documento.getFactura(pdf, factura, true, datosPaciente, 2,this.datosdian);
                                documento.getFactura(rep.pdf, factura, false, datosPaciente, rep.finalY + 10,this.datosdian);
                            } else {
                                documento.getFactura(pdf, factura, false, datosPaciente, 2,this.datosdian);
                            }
                        }
                 

                    }
                }

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async pdfEntrega(item) {
            try {
                if (this.getPermiso("verFacturaciones")) {
                    if (typeof window !== 'undefined') {
                        this.cita = item;
                        if (this.cita.estado.comaNombrecorto != 'ASISTIDA') {
                            if (this.cita.citaTipo == 1) {

                                let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
                                let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);

                                let ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
                                let x = 6;
                                let pdf = new jsPDF('p', 'mm', 'a4');
                                pdf.setFontSize(7);
                                pdf.setTextColor(0, 0, 0);
                                let ln = parseInt(ips.tblConfPersonasempresas[0].coemNombre.length / 35);
                                let nombre = pdf.splitTextToSize(ips.tblConfPersonasempresas[0].coemNombre, 35);
                                pdf.text(x + 20, 5, nombre, 'center');
                                ln = (ln + 1) * 4.5;
                                pdf.text(x + 20, ln + 3, `${ips.tipoidentificacion.comaNombrecorto} - ${ips.copeIdentificacion}`, 'center');

                                pdf.setFontSize(7);
                                pdf.text(x + 20, ln + 6, ips.copeDireccion, 'center');
                                pdf.text(x, ln + 9, ips.copeEmail.toLowerCase());
                                pdf.setDrawColor(0, 0, 0);
                                pdf.line(x, ln + 10, 55, ln + 10);
                                pdf.setFontSize(7);
                                pdf.text(x, ln + 13, 'Identificacion ' + this.paciente.tipoidentificacion.comaNombrecorto + ' - ' + this.paciente.copeIdentificacion);

                                pdf.text(x, ln + 16, 'Apellido1:' + this.cita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1);
                                pdf.text(x, ln + 19, 'Apellido2:' + this.cita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido2);
                                pdf.text(x, ln + 22, 'Nombre1:' + this.cita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1);
                                pdf.text(x, ln + 25, 'Nombre2:' + this.cita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre2);

                                pdf.text(x, ln + 28, 'Fecha de recibido:' + this.cita.tblCitaFacturaciones[0].cifaFecha);
                                pdf.text(x, ln + 31, 'Fecha de entrega:' + this.cita.tblCitaFacturaciones[0].cifaFechareclamo);
                                pdf.text(x, ln + 34, 'Resultados: de 3:30 p.m a 5:00 p.m');
                                pdf.text(x, ln + 37, 'Telefono: ' + ips.copeTelefono);
                                pdf.setDrawColor(0, 0, 0);
                                pdf.setFontSize(6);
                                pdf.setFont("bold");
                                pdf.line(x, ln + 38, 55, ln + 38);

                                pdf.text(x, ln + 41, 'IPS Tools (HIS - Hospital Information System)');
                                pdf.text(x, ln + 44, 'TEL: 3168775054');
                                pdf.text(x, ln + 47, '® 2020 Powered by www.jasoftwaresas.com');
                                pdf.line(x, ln + 48, 55, ln + 48);
                                if (navigator.userAgent.indexOf('irefox') > -1) {

                                    pdf.output("dataurlnewwindow");
                                } else {
                                    window.open(
                                        URL.createObjectURL(pdf.output("blob")),
                                        "_blank");
                                }
                            }
                            if (this.cita.citaTipo == 0) {
                                // if (this.cita.tblCitaFacturaciones.length == 0) {
                                //     this.cita.tblCitaFacturaciones.push(this.lista[0]);
                                //     //console.log(this.cita.tblCitaFacturaciones[0]);
                                // }
                                let ultimo = await this.$apiService.index(`cita/facturaciones/ultimoturno?fecha=${(this.cita.tblCitaFacturaciones.length >0)? this.cita.tblCitaFacturaciones[0].cifaFecha :new Date().yyyymmdd()}&cita=${this.cita.citaId}`).then(data => data);
                                let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
                                let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
                                let ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));

                                let pdf = new jsPDF('p', 'mm', 'a4');
                                pdf.setFontSize(8);
                                pdf.setTextColor(0, 0, 0);
                                let x = 6;
                                let ln = parseInt(ips.tblConfPersonasempresas[0].coemNombre.length / 35);
                                let nombre = pdf.splitTextToSize(ips.tblConfPersonasempresas[0].coemNombre, 35);
                                pdf.text(x + 20, 5, nombre, 'center');
                                ln = (ln + 1) * 4.5;
                                pdf.text(x + 20, ln + 4, `${ips.tipoidentificacion.comaNombrecorto} - ${ips.copeIdentificacion}`, 'center');
                                pdf.text(x + 20, ln + 8, 'TEL: ' + ips.copeTelefono, 'center');
                                pdf.setFontSize(7);
                                pdf.text(x, ln + 12, ips.copeDireccion);
                                pdf.text(x, ln + 16, ips.copeEmail);
                                pdf.setDrawColor(0, 0, 0);
                                pdf.line(x, ln + 17, 65, ln + 17);
                                pdf.setFontSize(18);
                                pdf.setFont(undefined, "bold");
                                pdf.text(35, ln + 24, 'TURNO ' + ultimo[0].cifaTipoTurno + (ultimo[0].sala ? (', ' + ultimo[0].sala) : ''),'center');
                                pdf.setFontSize(9);
                                pdf.setFont(undefined, "normal");
                                pdf.text(10, ln + 30, 'PACIENTE:');
                                pdf.text(10, ln + 34, ultimo[0].paciente_nombre1 + ' ' + ultimo[0].paciente_apellido1);
                                pdf.text(10, ln + 38, this.paciente.tipoidentificacion.comaNombrecorto + ' - ' + this.paciente.copeIdentificacion);
                                pdf.text(10, ln + 42, 'FECHA:' + ((this.cita.tblCitaFacturaciones.length > 0) ? this.cita.tblCitaFacturaciones[0].cifaFecha : new Date().yyyymmdd()));
                                pdf.text(10, ln + 46, 'PROFESIONAL:');
                                pdf.text(10, ln + 50, ultimo[0].empleado_nombre1 + ' ' + ultimo[0].empleado_apellido1);
                            
                                pdf.text(10, ln + 54, 'SERVICIO:');
                                let ln2 = parseInt(ultimo[0].coseNombre.length / 50);

                                let servicio = pdf.splitTextToSize(ultimo[0].coseNombre, 50);
                                pdf.text(10, ln + 58, servicio);
                                ln2 = (ln2 + 1) * 4.5;
                                ln = ln2 + ln;
                                pdf.setDrawColor(0, 0, 0);
                                pdf.setFontSize(7);
                                pdf.setFont("bold");
                                pdf.line(x, ln + 62, 65, ln + 62);
                                pdf.text(x, ln + 66, 'IPS Tools (HIS - Hospital Information System)');
                                pdf.text(x, ln + 70, 'TEL: 3168775054');
                                pdf.text(x, ln + 74, '® 2020 Powered by www.jasoftwaresas.com');
                                pdf.line(x, ln + 76, 65, ln + 76);
                                if (navigator.userAgent.indexOf('irefox') > -1) {

                                    pdf.output("dataurlnewwindow");
                                } else {
                                    window.open(
                                        URL.createObjectURL(pdf.output("blob")),
                                        "_blank");
                                }
                            }
                            this.cita = null;
                        } else {
                            this.$swal.fire({
                                title: "Cita atendida!",
                                text: "ya esta cita fue atendida",
                                icon: "warning"
                            });
                        }

                    }
                }

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        agregarOrden() {
            this.formOrdenes.push({
                ciorTipoOrden: this.tipoordenes.find(e => e.comaNombrecorto == ((this.cita.citaTipo == 1) ? 'TP_ORD_LAB' : 'TP_ORD_SER')).comaId,
                ciorObservacion: null,
                ciorTipoProcedimiento: (this.tipoprocedimiento.length > 0) ? this.tipoprocedimiento.find(e => e.comaNombrecorto == 'AUTORIZACION').comaId : null,
                cifaId: null,
                cocuId: null,
                cups: {
                    search: null,
                    isLoading: false,
                    cups: []
                },
            });

        },
        eliminarOrden(item) {
            this.formOrdenes = this.formOrdenes.filter(
                e =>
                this.formOrdenes.indexOf(e) !=
                this.formOrdenes.indexOf(item)
            );
        },
        agregarDiagnostico() {
            this.formDiagnosticos.push({

                cidiTipoCie10: null,

                cidiTipoDiagnostico: null,

                cidiTipoClase: null,

                cifaId: null,
                tipocie10: {
                    search: null,
                    isLoading: false,
                    tipocie10: []
                }

            });
        },
        eliminarDiagnostico(item) {
            this.formDiagnosticos = this.formDiagnosticos.filter(
                e =>
                this.formDiagnosticos.indexOf(e) !=
                this.formDiagnosticos.indexOf(item)
            );
        },
        async facturar(item) {
            this.cita = item;
            this.form = {

                cifaFecha:this.cita.citaFecha,
                cifaFechareclamo: new Date().yyyymmdd(),
                cifaCopago: 0,
                cifaCodigoVale: null,
                cifaMedicoremitente: null,
                cifaNumFactura: 0,
                citaTipoMes: null,
                cifaTipoFinalidad: null,
                citaId: this.cita.citaId,
                cocoId: this.cita.cocoId,
                cbdpId: this.cita.cbdpId,
                cifaNumeroAutorizacion: null,
                cifaTelemedicina: 0,
                serialId:null,
                cifaTipoTurno:null,
                cifaTipoSala:null,
                tiposala:this.tiposala,
                tipoturno:[]

            };
            /*if (this.cita.cocoId) {
                let serial = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSERIALES").then(data => data);
                serial = serial.filter(e => new Date(e.comaNombrelargo.split(';')[0].replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.comaNombrelargo.split(';')[1].replace(/-+/g, '/')).getTime() >= new Date().getTime());

                serial.sort((a, b) => parseInt(a.comaValor) - parseInt(b.comaValor));
                this.form.serialId = serial[0].comaId;
                this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${serial[0].comaId}`).then(data => data);
                this.form.cifaNumFactura = this.datosdian.find(e => e.comaNombrecorto == 'PREFIJO_SERIE').comaValor + (parseInt(this.datosdian.find(e => e.comaNombrecorto == 'NUMERO_ACTUAL').comaValor) + 1);

            }*/

            this.form.cifaTipoEstado = this.tipoestado.find(e => e.comaNombrelargo == 'HISTORIA ABIERTA PERMITE EDITAR').comaId;
            this.form.citaTipoMes = this.tipomes.find(e => e.comaNombrelargo.indexOf(this.meses[new Date(this.cita.citaFecha.replace(/-+/g, '/')).getMonth()]) != -1).comaId;
            if (this.cita.citaTipo == 0) {
                let dispo = await this.$apiService.index(`cita/disponibilidad/turnos3/${this.cita.coprId}/${this.cita.citaFecha}/${this.cita.citaHora}`).then(data => data);
                if (dispo.length > 0) {
                    this.form.tipoturno = [];
                    for (const turno of dispo) {
                        this.form.tipoturno.push(turno);
                    }
                    this.form.cifaTipoTurno = Math.min(...this.form.tipoturno.map(item => item.comaId));
                } else {
                    this.form.tipoturno = [];
                }
            }
            if (this.cita.citaTipo == 1 || this.cita.citaTipo == 2 || this.cita.citaTipo == 3) {
                this.formDiagnosticos = [{

                    cidiTipoCie10: null,

                    cidiTipoDiagnostico: null,

                    cidiTipoClase: null,

                    cifaId: null,
                    tipocie10: {
                        search: null,
                        isLoading: false,
                        tipocie10: []
                    }

                }];
            }
            if (this.cita.citaTipo == 1 || this.cita.citaTipo == 3) {
                this.formOrdenes = [{
                    ciorTipoOrden: this.tipoordenes.find(e => e.comaNombrecorto == ((this.cita.citaTipo == 1) ? 'TP_ORD_LAB' : 'TP_ORD_SER')).comaId,
                    ciorTipoProcedimiento: (this.tipoprocedimiento.length > 0) ? this.tipoprocedimiento.find(e => e.comaNombrecorto == 'AUTORIZACION').comaId : null,
                    ciorObservacion: null,
                    cifaId: null,
                    cocuId: null,
                    ciorCantidad: 0,
                    cups: {
                        search: null,
                        isLoading: false,
                        cups: []
                    },
                }];
            }
        },
        async limpiar() {
            this.form = null;
            this.cita = null;
            this.formOrdenes = [];
            this.formDiagnosticos = [];
        },
        close() {
            this.dialog = false;
            this.limpiar();
        }
    }

}
</script>

<style>

</style>
