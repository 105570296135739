<style>

</style>

<template>
<v-container lighten-5>
    <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="citaFecha" class="elevation-1">
        <template v-slot:header>
            <Dfiltros v-if="filtrar" :onBuscar="buscar" v-bind:filtros="filtros" />
            <v-toolbar dark color="primary darken-3" class="mb-1">
                <v-toolbar-title class="white--text">Citas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn class="mx-2" dark tile color="purple" @click="filtrar=!filtrar">
                    Filtros
                </v-btn>
                <!-- <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                    agregar
                </v-btn> -->
            </v-toolbar>
        </template>
        <template v-slot:default="props">
            <v-row align="center" justify="center">
                <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">
                            <v-row no-gutters>

                                <v-col cols="9" sm="9" md="9" lg="9">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="5" md="5" lg="5">
                                            FECHA <span class="green--text"> {{item.citaFecha}}</span>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="7" lg="7">
                                            PACIENTE <span class="primary--text">
                                                {{ item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}
                                            </span>
                                        </v-col>

                                        <v-col cols="12" sm="5" md="5" lg="5">
                                            IDENT. <span class="primary--text">
                                                {{item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}

                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="7" lg="7">
                                            EDAD <span class="primary--text">{{ new Date().calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento) }}
                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="5" md="5" lg="5">
                                            TURNO <span class="primary--text">{{ item.cifaTipoTurno }}
                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="5" md="5" lg="5">
                                            ESTADO <span class="primary--text">{{ item.estado.comaNombrecorto }}
                                            </span>
                                        </v-col>

                                    </v-row>
                                </v-col>
                                <v-col cols="3" sm="3" md="3" lg="3">
                                    <v-menu transition="slide-y-transition" v-if="item.estado.comaNombrecorto =='ASISTIDA'" bottom>
                                            <template v-slot:activator="{ on }">

                                                <v-btn outlined color="orange" :loading="loading" class="mx-2" small v-on="on">
                                                    <v-icon>mdi-file-pdf-box</v-icon>
                                                </v-btn>

                                            </template>
                                            <v-list>
                                                <v-list-item @click="pdf(item)">
                                                    <v-list-item-title>Historia</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="pdfRecetarios(item)">
                                                    <v-list-item-title>Recetarios </v-list-item-title>
                                                </v-list-item>

                                                <v-list-item @click="pdfOrdenes(item)">
                                                    <v-list-item-title>Ordenes </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="pdfRemiciones(item)">
                                                    <v-list-item-title> Remiciones </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="pdfIncapacidad(item)">
                                                    <v-list-item-title> Incapacidades </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="pdfCertificado(item)">
                                                    <v-list-item-title> Certificados </v-list-item-title>
                                                </v-list-item>

                                            </v-list>
                                        </v-menu>
                                    <v-tooltip top v-if="item.estado.comaNombrecorto == 'ACTIVA'">
                                        <template v-slot:activator="{ on }">
                                            <v-btn outlined small class="mx-2" color="error" v-on="on" @click="cancelar(item.citaId)">
                                                <v-icon>mdi-block-helper</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Cancelar</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3" lg="3">
                                    EPS <span class="primary--text">
                                        <template v-if="item.tblConfBasedatospacientes.length > 0">
                                            {{ item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre }}
                                        </template>
                                        <template v-else-if="item.cocoId">
                                            {{ item.tblConfContrato.tblConfPersonasempresas[0].coemNombre }}
                                        </template>
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4">
                                    EMPLEADO
                                    <span class="primary--text"> {{ item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 }} </span>
                                    <span class="">{{item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}</span>
                                </v-col>
                                <v-col cols="12" sm="12" md="5" lg="5">
                                    SERVICIO <span class="primary--text"> {{ item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}}</span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:no-data>
            <v-row class="mt-2" align="center" justify="center">
                <v-col cols="10" sm="10" md="10" lg="10">
                    <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                        No se encontro nada.
                    </v-alert>
                </v-col>
            </v-row>
        </template>
        <template v-slot:footer>
            <v-row class="mt-2 pb-5" align="center" justify="center">
                <span class="mr-4 grey--text">
                    Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                </span>
                <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

            </v-row>
        </template>
    </v-data-iterator>
    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog" persistent width="1200px" min-width="1200px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <template v-if="form.coppId && tipo == 'CAPITADO'">
                            <v-alert text prominent dense type="success" v-if="bd.length > 0">
                                El paciente se encuentra en las base de datos de <template v-for="b in bd"> {{ ','+ b.cocoNombre  }}</template>
                            </v-alert>
                            <v-alert text prominent dense type="error" v-else>
                                El paciente no se encuentra en ninguna base de datos para el mes de {{ meses[ new Date().getMonth()]}}.
                            </v-alert>
                        </template>

                        <v-row justify="left" align="left">

                            <!-- <v-col cols="12" sm="12" md="2">
                                <v-switch v-model="tipo" true-value="EVENTO" false-value="CAPITADO" :label="tipo"></v-switch>
                            </v-col> -->
                            <v-col cols="12" sm="12" md="10" v-if="tipo=='EVENTO'">
                                <v-autocomplete outlined v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" @change="getServicios" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="10" v-else-if="bd.length > 0 ">
                                <v-autocomplete outlined v-model="form.cbdpId" :items="bd" item-text="cocoNombre" item-value="cbdpId" item-color="primary" label="EPS contrada" @change="getServicios" clearable>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title>{{data.item.cocoNombre}}</v-list-item-title>
                                                <v-list-item-subtitle> {{data.item.coemNombre}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="(form.coppId  && bd.length > 0) || (form.coppId && form.cocoId)">
                                <v-autocomplete outlined :error="$v.form.cocsId.$error" required v-model="form.cocsId" :items="servicios" item-text="tblConfServicios[0].coseNombre" item-value="cocsId" item-color="primary" label="Servicio" clearable @change="getEmpleados">
                                    <template v-slot:selection="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.tblConfServicios[0].coseNombre"></v-list-item-title>
                                            <v-list-item-subtitle class="primary--text" v-html="data.item.tblConfContrato.tblConfPersonasempresas[0].coemNombre"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-html="data.item.tblConfCup.cocuNombre"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.tblConfServicios[0].coseNombre"></v-list-item-title>
                                                <v-list-item-subtitle class="primary--text" v-html="data.item.tblConfContrato.tblConfPersonasempresas[0].coemNombre"></v-list-item-subtitle>
                                                <v-list-item-subtitle v-html="data.item.tblConfCup.cocuNombre"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>

                            </v-col>

                            <v-col cols="12" sm="12" md="12" v-if="form.cocsId && form.coppId">
                                <v-autocomplete outlined :error="$v.form.coprId.$error" required v-model="form.coprId" :items="empleados" :filter="(item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' '+item.tblConfPersonasnaturales[0].copnNombre2 + ' '+item.tblConfPersonasnaturales[0].copnApellido1 + ' '+item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1 " item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" item-color="primary" label="Medico" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.copeIdentificacion">
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>

                            </v-col>
                            <v-col cols="12" sm="12" md="3" v-if="form.coppId">
                                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" v-if="form.coppId && form.coprId">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field required outlined :error="$v.form.citaFecha.$error" v-model="form.citaFecha" label="Fecha de la cita" prepend-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES" :min="(user.role.indexOf('ADMIN') >-1)?'2000-01-01':new Date().yyyymmdd()" :allowed-dates="allowedDates" v-model="form.citaFecha" @input="menu = false" @change="disponibilidad"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="12" md="3" v-if="form.coppId && form.coprId ">
                                <v-autocomplete outlined :error="$v.form.citaTipoJornada.$error" required v-model="form.citaTipoJornada" :items="tipojornada" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Jornada" @change="disponibilidad" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="form.coppId && form.coprId && form.citaFecha && !(dispo.length > 0 )">
                                <v-alert text prominent dense type="error">
                                    El medico no tiene turnos disponibles para esta fecha.
                                </v-alert>
                            </v-col>

                            <v-col cols="12" sm="12" md="3" v-if="form.coppId && form.citaTipoJornada && form.coprId && dispo.length > 0 ">
                                <v-autocomplete outlined :error="$v.form.citaTipoTurno.$error" required v-model="form.citaTipoTurno" :items="tipoturno" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Turno" clearable></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" :loading="loadingCita" dark @click="agregar" v-if="form.coppId && form.coprId && dispo.length > 0">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</v-container>
</template>

<script>
import {
    required
}
from 'vuelidate/lib/validators'
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
    documentos
}
from "../../utils/documentos";

export default {
    
    data() {
        return {
            loadingCita: false,
            nuevo: false,
            menu: false,
            VERSION: this.$cookies.get("VERSION"),
            LoadingExp: false,
            tipo: this.$cookies.get("DEFAULT"),
            Count: 0,
            tab: "tab-1",
            page: 1,
            loading: false,
            pageCount: 0,
            dialog: false,
            isNew: true,
            copeId: null,
            isLoading: false,
            cambio: null,
            searchP: null,
            meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
            bd: [],
            url: "cita/citas/",
            user: this.$cookies.get("user"),
            token: this.$cookies.get("token"),
            
            nameRules: [v => !!v || "Campo requirido"],
            //maestra: [],
            empleados: [],
            servicios: [],
            servicios2: [],
            tipojornada: [],
            tipoturno: [],
            tipoestado: [],
            dispo: [],
            paciente: null,
            form: {

                citaFecha: null,

                citaTipoJornada: null,

                citaTipoTurno: null,

                citaTipoEstado: null,
                citaTipo: 0,
                cbdpId: null,
                coppId: null,

                coprId: null,

                cocsId: null,
                cocoId: null,
            },
            filtros: [{
                    text: "Fecha",
                    value: null,
                    tipo: "fecha",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },

                {
                    text: "Estado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                },

                {
                    text: "Medico",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
                    filter: (item, queryText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                }, {
                    text: "Servicio",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "coseNombre",
                    listavalue: "coseId",
                },
                {
                    text: "Rango de fecha",
                    value: [],
                    tipo: "rango",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },

            ],
            lista: [],
            listaCopia: [],
            contratos: [],
            secciones: [],
            campos: [],
            Historia: [],
            historial: [],
            diagnosticos: [],
            ordenes: [],
            recetarios: [],
            remisiones: [],
            dientesplaca: [],

            dientesodontograma: []

        };
    },
    validations: {
        form: {

            citaFecha: {
                required,
            },

            citaTipoJornada: {
                required,
            },

            citaTipoTurno: {
                required,
            },

            citaTipoEstado: {
                required,
            },

            coppId: {
                required,
            },

            coprId: {
                required,
            },

            cocsId: {
                required,
            },
        },
    },
    computed: {

        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },

    created: async function () {

        
        if (this.$cookies.get('user').role == 'PACIENTE') {

            this.$store.commit('setCargaDatos', true);
            this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);

            this.filtros[2].lista = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
            this.filtros[3].lista = await this.$apiService.index("sistema/servicios/index/").then(data => data);

            this.tipojornada = await this.$apiService.index("sistema/maestra/index/TBL_TIPOJORNADA").then(data => data);
            this.tipoturno = await this.$apiService.index("sistema/maestra/index/TBL_TIPOTURNO").then(data => data);
            this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
            this.contratos = this.contratos.filter(e => new Date(e.cocoFechainicio.replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.cocoFechafinal.replace(/-+/g, '/')).getTime() >= new Date().getTime() && e.tipocontrato.comaNombrecorto == 'EVENTO_CON' || e.tipocontrato.comaNombrecorto == 'EVENTO_SUB');
            await this.buscar(this.filtros);
            this.form.citaTipoEstado = this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId;
            this.form.citaFecha = this.currentDate(new Date());
            this.form.coppId = this.$cookies.get('user').empleado;
            this.paciente = await this.$apiService.index(`sistema/personas/view/${this.$cookies.get('user').empleado}/pacientes?tipo=paciente`).then(data => data);
            await this.cargarEps();
            this.$store.commit('setCargaDatos', false);
        }

    },

    
    methods: {
       
        allowedDates: val => new Date(val.replace(/-+/g, '/')).getDay() != 0 && new Date(val.replace(/-+/g, '/')).getDay() != 6,
        currentDate(date) {
            const fecha = date;
            if (this.allowedDates(fecha.yyyymmdd())) {
                return fecha.yyyymmdd()
            } else {
                fecha.setDate(fecha.getDate() + 1);
                return this.currentDate(fecha);

            }
        },
        async cargarEps() {
            this.$apiService.index("cita/basedatospacientes/index/" + this.form.coppId + "/" + new Date().yyyymmdd()).then(data => {
                if (data.length > 0) {
                    this.bd = data;
                } else {
                    this.bd = [];
                }

            });
        },

        async searchCita(searchP) {
            this.$store.commit('setCargaDatos', true);
            const data = await this.$apiService.index(`cita/citas/buscar/0?${searchP}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.$store.commit('setCargaDatos', false);
        },

        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `offset=${(this.page-1)*10}&`;

            if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                search = search + `citaFecha=${this.filtros[0].value}&`;
            }

            if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
                search = search + `citaTipoEstado=${this.filtros[1].value}&`;

            }
            search = search + `coppId=${this.$cookies.get('user').empleado}&`;

            if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
                search = search + `coprId=${this.filtros[2].value}&`;

            }
            if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
                search = search + `coseId=${this.filtros[3].value}&`;

            }
            if (this.filtros[4].value != null && this.filtros[4].value.length > 1) {
                search = search + `citaFecharango=${this.filtros[4].value[0]},${this.filtros[4].value[1]}&`;
                this.filtros[4].value = [];
            }
            if (search != "") {
                await this.searchCita(search);
            }

        },
        async getEmpleados() {
            if (this.form.cocsId) {
                this.empleados = await this.$apiService.index("sistema/Personasempleadosservicios/empleados/" + this.servicios.find(e => e.cocsId == this.form.cocsId).coseId).then(data => data);
                this.empleados = this.empleados.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].estado.comaNombrecorto == 'ACTIVO');
            }
        },
        async getServicios() {
            if (this.form.cocoId) {
                let data = await this.$apiService.index("sistema/contratosxservicios/contrato/" + this.form.cocoId).then(data => data);
                this.servicios = data;
                this.servicios = this.servicios.filter(e => e.tblConfServicios[0].tipo.comaNombrelargo == 'MORBILIDAD' &&
                    (e.tblConfServicios[0].coseSexo == 0 || e.tblConfServicios[0].coseSexo == ((this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
                    ((new Date().getTime() - new Date(this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.tblConfServicios[0].coseEdadinicio

                    &&
                    ((new Date().getTime() - new Date(this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.tblConfServicios[0].coseEdadfinal)

            } else {
                if (this.bd.length > 0) {
                    if (this.bd.find(e => e.cbdpId == this.form.cbdpId)) {
                        let data = await this.$apiService.index("sistema/contratosxservicios/contrato/" + this.bd.find(e => e.cbdpId == this.form.cbdpId).cocoId).then(data => data);
                        this.servicios = this.servicios.concat(data);

                        this.servicios = this.servicios.filter(e => e.tblConfServicios[0].tipo.comaNombrelargo == 'MORBILIDAD' &&
                            (e.tblConfServicios[0].coseSexo == 0 || e.tblConfServicios[0].coseSexo == ((this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
                            ((new Date().getTime() - new Date(this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.tblConfServicios[0].coseEdadinicio

                            &&
                            ((new Date().getTime() - new Date(this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.tblConfServicios[0].coseEdadfinal)

                    }
                }
            }

        },
        async disponibilidad() {
            if (this.form.citaTipoJornada) {
                this.dispo = await this.$apiService.index(`cita/disponibilidad/turnos2/${this.form.coprId}/${this.form.citaFecha }/${this.form.citaTipoJornada}`).then(data => data);
                if (this.dispo.length > 0) {
                    this.tipoturno = [];
                    for (const turno of this.dispo) {
                        this.tipoturno.push(turno);
                    }
                } else {
                    this.dispo = [];
                    this.tipoturno = [];

                }
            }

        },
        async agregar() {
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                this.loadingCita = true;
                try {
                    if (this.isNew) {

                        let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);

                        if (data) {
                            await this.buscar(this.filtros);

                        }
                        this.close();
                    } else {

                        var id = this.form.citaId;
                        let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);

                        if (data) {
                            this.lista = this.lista.filter(e => e.citaId != id);
                            this.listaCopia = this.lista.push(data);
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });

                        }

                        this.close();

                    }

                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
                this.loadingCita = false;
            }
        },
        async cancelar(id) {

            try {

                let data = await this.$apiService.update(this.url + "cancelar/" + id + "/", null).then(data => data);

                if (data) {
                    this.lista = this.lista.filter(e => e.citaId != id);
                    this.listaCopia = this.lista.push(data);
                    this.$swal.fire({
                        title: "Completado!",
                        text: "Cita cancelada",
                        icon: "success",
                        confirmButtonText: "Ok"
                    });

                }

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async editar(id) {
            try {

                this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);

                this.isNew = false;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        generarDientesplaca(t, l, ini, fin, valor, tipo, tipo2) {
            let dientes = [];
            let left = 0;
            for (let i = ini; i < fin; i++) {
                let parteTop = {
                    numero: valor,
                    convencion: null,
                    id: "parte6-diente-" + valor,
                    name: "parte6",
                    backgroundColor: "#FFFFFF",
                    top: t + 63,
                    left: l + 53,

                };
                let parteBottom = {
                    numero: valor,
                    convencion: null,
                    id: "parte7-diente-" + valor,
                    name: "parte7",
                    backgroundColor: "#FFFFFF",
                    top: t + 113,
                    left: l + 53
                };
                let parteLeftTop = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte1-diente-" + valor,
                    name: "parte1",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 38
                };
                let parteRightTop = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte2-diente-" + valor,
                    name: "parte2",
                    backgroundColor: "#FFFFFF",
                    top: t + 73,
                    left: l + 53
                };
                let parteLeftBottom = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte3-diente-" + valor,
                    name: "parte3",
                    backgroundColor: "#FFFFFF",
                    top: t + 103,
                    left: l + 53
                };
                let parteRightBottom = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte4-diente-" + valor,
                    name: "parte4",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 68
                };
                let parteMedio = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte5-diente-" + valor,
                    name: "parte5",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 53
                };

                if (i > ini) {
                    left = 70;

                    parteTop.left = parteTop.left + left * (i - ini);
                    parteBottom.left = parteBottom.left + left * (i - ini);
                    parteLeftTop.left = parteLeftTop.left + left * (i - ini);
                    parteRightTop.left = parteRightTop.left + left * (i - ini);
                    parteLeftBottom.left = parteLeftBottom.left + left * (i - ini);
                    parteRightBottom.left = parteRightBottom.left + left * (i - ini);
                    parteMedio.left = parteMedio.left + left * (i - ini);
                }

                dientes.push({
                    id: "diente-" + valor,
                    convencion: null,
                    numero: valor,
                    partes: (tipo2 == 'PLACA') ? {
                        //parteTop: parteTop,
                        //parteBottom: parteBottom,
                        parteLeftTop: parteLeftTop,
                        parteRightTop: parteRightTop,
                        parteLeftBottom: parteLeftBottom,
                        parteRightBottom: parteRightBottom,
                        parteMedio: parteMedio
                    } : {
                        parteTop: parteTop,
                        parteBottom: parteBottom,
                        parteLeftTop: parteLeftTop,
                        parteRightTop: parteRightTop,
                        parteLeftBottom: parteLeftBottom,
                        parteRightBottom: parteRightBottom,
                        parteMedio: parteMedio
                    }
                });
                if (tipo == 'negativo') {
                    valor = valor - 1;
                } else {
                    valor = valor + 1;
                }

            }
            return dientes;
        },
        generarDientes(tipo) {
            let dientes = [];
            dientes = dientes.concat(this.generarDientesplaca(0, 0, 0, 8, 18, 'negativo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(0, 590, 8, 16, 21, 'positivo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(100, 210, 16, 21, 55, 'negativo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(100, 590, 21, 26, 61, 'positivo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(200, 210, 26, 31, 85, 'negativo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(200, 590, 31, 36, 71, 'positivo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(300, 0, 36, 44, 48, 'negativo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(300, 590, 44, 52, 31, 'positivo', tipo));
            return dientes;
        },
        async dibujarH(item) {
            this.loading = true;
            this.Historia = [];
            this.secciones = [];
            this.campos = [];
            this.diagnosticos = [];
            this.ordenes = [];
            this.recetarios = [];
            this.remisiones = [];
            var pesos = [];
            var vacunas = [];
            var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
            this.ordenes = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/orden?tipo=AUTORIZACION").then(data => data);
            this.recetarios = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/recetario").then(data => data);
            this.remisiones = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/remision").then(data => data);
            this.incapacidades = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/incapacidadcertificado?tipo=INCAPACIDAD").then(data => data);
            this.certificados = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/incapacidadcertificado?tipo=CERTIFICADO").then(data => data);

            this.Historia = await this.$apiService.index("sistema/historiasfacturaciones/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId).then(data => data);
            if (datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre == 'RUTA PARA LA PRIMERA INFANCIA') {
                let reportes = await this.$apiService.index("reporte/reportes/index?offset=0&coreNombre=HISTORIAL_PRIMERA_INFANCIA").then(data => data);
                if (reportes) {
                    let r = reportes.rows[0];
                    r.tblConfReportesparametros.forEach(async o => {
                        o.Valor = datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion;
                    });
                    pesos = await this.$apiService.create("reporte/reportes/ejecutar/", r).then(data => data);

                }
            }
            if (datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre == 'ODONTOLOGÍA GRAL (PRIMERA VEZ)' || datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre == 'HIGIENE ORAL') {

                this.dientesplaca = this.generarDientes('PLACA');

                let yatiene = await this.$apiService.index("cita/odontologicas/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/0").then(data => data);
                if (yatiene.length > 0) {
                    yatiene.forEach(diente => {
                        let d = this.dientesplaca.find(e => e.numero == diente.ciodDiente);
                        if (d) {
                            if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.placa = diente.ciodPlaca;
                            if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.placa = diente.ciodPlaca;
                            if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.placa = diente.ciodPlaca;
                            if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.placa = diente.ciodPlaca;
                            if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.placa = diente.ciodPlaca;
                        }
                    });
                }

                this.dientesodontograma = this.generarDientes('ODONTOGRAMA');

                let yatieneO = await this.$apiService.index("cita/odontologicas/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/1").then(data => data);
                if (yatieneO.length > 0) {
                    yatieneO.forEach(diente => {
                        let d = this.dientesodontograma.find(e => e.numero == diente.ciodDiente);
                        if (d) {
                            if (diente.ciodParte) {
                                if (d.partes.parteTop.id == diente.ciodParte) d.partes.parteTop.convencion = diente.ciodConvencion;
                                if (d.partes.parteBottom.id == diente.ciodParte) d.partes.parteBottom.convencion = diente.ciodConvencion;
                                if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.convencion = diente.ciodConvencion;
                                if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.convencion = diente.ciodConvencion;
                                if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.convencion = diente.ciodConvencion;
                                if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.convencion = diente.ciodConvencion;
                                if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.convencion = diente.ciodConvencion;
                            } else {
                                d.convencion = diente.ciodConvencion;
                            }
                        }
                    });
                }
            }
            let reportes = await this.$apiService.index("reporte/reportes/index?offset=0&coreNombre=VACUNAS_PACIENTE").then(data => data);
            if (reportes) {
                let r = reportes.rows[0];
                r.tblConfReportesparametros.forEach(async o => {
                    o.Valor = datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion;
                });
                vacunas = await this.$apiService.create("reporte/reportes/ejecutar/", r).then(data => data);

            }
            if (this.Historia.length > 0) {
                this.historial = await this.$apiService.index(`sistema/historiasfacturaciones/historial/${datosPaciente.coppId}/${datosPaciente.tblCitaFacturaciones[0].cifaFecha}?cocsId=${datosPaciente.cocsId}`).then(data => data);
                this.Historia.forEach((historia) => {
                    if (this.secciones.find(e => e.hiseId == historia.tblConfHistoriaseccione.seccion.hiseId) != undefined) {
                        this.secciones.find(e => e.hiseId == historia.tblConfHistoriaseccione.seccion.hiseId).campos.push(historia);
                    } else {
                        historia.tblConfHistoriaseccione.seccion.campos = [];
                        historia.tblConfHistoriaseccione.seccion.campos.push(historia);
                        this.secciones.push(historia.tblConfHistoriaseccione.seccion);
                    }
                });
                this.secciones.sort((a, b) => parseInt(a.hiseOrden) - parseInt(b.hiseOrden));
                this.secciones.forEach((seccion) => {
                    seccion.campos.sort((a, b) => parseInt(a.tblConfHistoriaseccione.hiseOrden) - parseInt(b.tblConfHistoriaseccione.hiseOrden));
                });
                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));
                    let pdf = new jsPDF();

                    documento.getHistoria(pdf, datosPaciente, this.Historia, this.secciones, /*this.campos,*/ this.ordenes, this.diagnosticos, this.remisiones, this.recetarios, this.historial, this.dientesplaca, this.dientesodontograma, pesos, this.incapacidades, this.certificados, vacunas);
                }
                this.loading = false;
            } else {
                this.loading = false;
                this.$swal.fire({
                    title: "Historia no tramitada!",
                    text: "primero debe ser llenado la historia",
                    icon: "error"
                });
            }

        },
        async pdfDiagnosticos(item) {
            this.loading = true;
            var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
            this.diagnosticos = [];

            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
            if (this.diagnosticos.length) {

                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));
                    let pdf = new jsPDF();
                    documento.getDiagnosticos(pdf, this.diagnosticos, false, datosPaciente, 2);
                }
                this.loading = false;
            } else {
                this.loading = false;
                this.$swal.fire({
                    title: "No hay diagnosticos tramitadas!",
                    icon: "error"
                });
            }
        },
        async pdfRecetarios(item) {
            this.diagnosticos = [];
            this.loading = true;

            this.recetarios = [];
            var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);

            this.recetarios = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/recetario").then(data => data);
            if (this.recetarios.length) {
                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));

                    let pdf = new jsPDF();
                    documento.getRecetarios(pdf, this.recetarios, false, datosPaciente, 2, this.diagnosticos);
                }
                this.loading = false;
            } else {
                this.loading = false;
                this.$swal.fire({
                    title: "No hay recetarios tramitadas!",
                    icon: "error"
                });
            }
        },

        async pdfOrdenes(item) {
            this.loading = true;
            this.diagnosticos = [];

            this.ordenes = [];
            var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);

            this.ordenes = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/orden?tipo=AUTORIZACION").then(data => data);
            if (this.ordenes.length) {
                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));

                    let pdf = new jsPDF();
                    documento.getOrdenes(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
                }
                this.loading = false;
            } else {
                this.loading = false;
                this.$swal.fire({
                    title: "No hay ordenes tramitadas!",
                    icon: "error"
                });
            }
        },
        async pdfRemiciones(item) {
            this.diagnosticos = [];
            this.loading = true;

            this.remisiones = [];
            var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);

            this.remisiones = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/remision").then(data => data);
            if (this.remisiones.length) {
                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));

                    let pdf = new jsPDF();
                    documento.getRemisiones(pdf, this.remisiones, false, datosPaciente, 2, this.diagnosticos);
                }
                this.loading = false;
            } else {
                this.loading = false;
                this.$swal.fire({
                    title: "No hay remisiones tramitadas!",
                    icon: "error"
                });
            }
        },
        async pdfIncapacidad(item) {
            this.diagnosticos = [];

            this.incapacidades = [];
            var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);

            this.incapacidades = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/incapacidadcertificado?tipo=INCAPACIDAD").then(data => data);
            if (this.incapacidades.length) {
                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));

                    let pdf = new jsPDF();
                    documento.getIncapacidades(pdf, this.incapacidades, false, datosPaciente, 2, this.diagnosticos);
                }
            } else {
                this.$swal.fire({
                    title: "No hay incapacidades tramitadas!",
                    icon: "error"
                });
            }
        },
        async pdfCertificado(item) {
            this.diagnosticos = [];

            this.certificados = [];
            var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);

            this.certificados = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/incapacidadcertificado?tipo=CERTIFICADO").then(data => data);
            if (this.certificados.length) {
                if (typeof window !== 'undefined') {
                    let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("DEFAULT"));

                    let pdf = new jsPDF();
                    documento.getCertificados(pdf, this.certificados, false, datosPaciente, 2, this.diagnosticos);
                }
            } else {
                this.$swal.fire({
                    title: "No hay certificados tramitadas!",
                    icon: "error"
                });
            }
        },
        limpiar() {
            this.form = {

                citaFecha: this.currentDate(new Date()),

                citaTipoJornada: null,

                citaTipoTurno: null,

                citaTipoEstado: this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId,
                citaTipo: 0,
                coppId: this.$cookies.get('user').empleado,

                coprId: null,
                cbdpId: null,
                cocsId: null,
                cocoId: null,

            };
        },
        close() {

            this.isNew = true;
            this.dialog = false;
            this.limpiar();
        }
    }
};
</script>
