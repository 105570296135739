<template>
    <div>
        <v-toolbar dense flat class="light-blue darken-4" dark>

            <v-toolbar-title>Panel de {{(form.citaTipo==0)?'citas':(form.citaTipo==1)?'laboratorios':(form.citaTipo==2)?'procedimientos':(form.citaTipo==3)?'orden de servicios':(form.citaTipo==3)?'medicamentos':''}}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn text class="white" color="black" :to="{name:'Admisiones',params:{tab:(form.citaTipo==0)?'tab-1':(form.citaTipo==1)?'tab-2':(form.citaTipo==2)?'tab-3':(form.citaTipo==3)?'tab-4':(form.citaTipo==3)?'tab-5':''}}">
                <v-icon>mdi-chevron-left</v-icon>
                regresar
            </v-btn>
        </v-toolbar>

        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
                Facturar
                <v-icon>mdi-calendar</v-icon>
            </v-tab>
        </v-tabs>
        <v-card class="pa-2" outlined tile>
            <v-container>

                <v-row class="caption" no-gutters>
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">FECHA: </label>

                        <label class="font-weight-black">{{ form.citaFecha}}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">PACIENTE: </label>

                        <label class="font-weight-black" v-if="form.tblConfPersonaspacientes.length > 0">{{ form.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +form.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="form.citaTipo==0">
                        <label class="primary--text">EMPLEADO: </label>

                        <label class="font-weight-black" v-if="form.tblConfPersonasempleados.length > 0">{{ form.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + " " +form.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}</label>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" v-if="form.tblConfPersonaspacientes.length > 0">
                        <label class="primary--text">EDAD: </label>

                        <label class="font-weight-black">
                            {{ new Date().calcularEdad(form.tblConfPersonaspacientes[0].coppFechanacimiento) }}
                        </label>

                    </v-col>
                    <template v-if="form.cbdpId">
                        <v-col cols="12" sm="12" md="4" v-if="form.tblConfBasedatospacientes.length > 0">
                            <label class="primary--text">EPS: </label>

                            <label class="font-weight-black"> {{(form.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':form.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre}}</label>

                        </v-col>
                    </template>
                    <v-col cols="12" sm="12" md="4" v-if="form.cocoId">
                        <label class="primary--text">EPS: </label>

                        <label class="font-weight-black"> {{(form.tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':form.tblConfContrato.tblConfPersonasempresas[0].coemNombre}}</label>

                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="form.citaTipo==0 && form.jornada">
                        <label class="primary--text">JORNADA: </label>

                        <label class="font-weight-black">{{form.jornada.comaNombrecorto}}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="form.citaTipo==0 && form.jornada">
                        <label class="primary--text">TURNO: </label>

                        <label class="font-weight-black">{{form.cifaTipoTurno}}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="form.citaTipo==0 && form.citaHora">
                        <label class="primary--text">HORA: </label>

                        <label class="font-weight-black">{{form.citaHora}}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" v-if="form.estado">
                        <label class="primary--text">ESTADO: </label>

                        <label class="font-weight-black"> {{ form.estado.comaNombrecorto}}</label>

                    </v-col>
                    <v-col cols="12" sm="12" md="10" v-if="form.citaTipo==0">
                        <span class="primary--text">SERVICIO: </span>

                        <label class="font-weight-black" v-if="form.tblConfContratosxservicios.length > 0">{{form.tblConfContratosxservicios[0].tblConfCup.cocuNombre}}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="form.citaTipo==2">
                        <span class="primary--text">CUP: </span>

                        <label class="font-weight-black" v-if="form.tblConfCup">{{form.tblConfCup.cocuNombre}}</label>
                    </v-col>

                </v-row>
            </v-container>
        </v-card>
        <v-tabs-items v-model="tab">

            <v-tab-item value="tab-1">
                <facturar :cita="form" v-if="form.citaId" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import Facturar from "./Facturaciones";
export default {
  components: {
    Facturar
  },
  data() {
    return {
      tab: "tab-1",
      user: null,
      url: "cita/citas/",
      token: this.$cookies.get("token"),

      tipojornada: [],
      tipoturno: [],
      tipoestado: [],
      form: {

        citaFecha: new Date().yyyymmdd(),

        citaTipoJornada: null,

        citaTipoTurno: null,

        citaTipoEstado: null,
        citaTipo: null,
        citaCantidad: null,
        coppId: null,

        coprId: null,

        cocsId: null,
        cocuId: null,
        tblConfContratosxservicios: [],
        tblConfPersonasempleados: [],
        tblConfPersonaspacientes: [],
        tblConfCups: [],
      },
    }
  },
  created: async function() {
    if (this.$route.params.id) {
      this.$store.commit('setCargaDatos', true);
      this.form = await this.$apiService.index(this.url + "view/" + this.$route.params.id).then(data => data);
      //this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);

      //this.tipojornada = await this.$apiService.index("sistema/maestra/index/TBL_TIPOJORNADA").then(data => data);
      // this.tipoturno = await this.$apiService.index("sistema/maestra/index/TBL_TIPOTURNO").then(data => data);
      this.$store.commit('setCargaDatos', false);
      this.isNew = false;
    }
  },
  methods: {
    getMaestra(id, maestra) {
      if (id != null) {

        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrelargo;
          }
        }
      }

    },

    limpiar() {
      this.form = {
        citaFecha: new Date().yyyymmdd(),

        citaTipoJornada: null,

        citaTipoTurno: null,

        citaTipoEstado: null,
        citaTipo: null,
        citaCantidad: null,
        coppId: null,

        coprId: null,

        cocsId: null,
        cocuId: null,
        tblConfContratosxservicios: [],
        tblConfPersonasempleados: [],
        tblConfPersonaspacientes: [],
        tblConfCups: [],
      };
    },
  }
}

</script>
