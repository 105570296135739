<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <span class="primary--text display-2">Admision rapida</span><br>
        </v-col>
        <v-col cols="auto" md="12">
            <v-card>
                <v-card-title>
                    <span class="black--text headline">Agendar citas</span>
                    <div class="flex-grow-1"></div>
                    <v-btn text class="primary" dark :to="{name:'Admision'}">
                        <v-icon>mdi-chevron-left</v-icon>
                        regresar
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row class="grey lighten-3" v-if="paciente">
                        <v-col cols="12" sm="12" md="3">
                            <v-icon color="primary" style="font-size:200px;">mdi-account-check</v-icon>
                        </v-col>
                        <v-col cols="12" sm="12" md="9">
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">NOMBRE: </label>
                                    {{paciente.tblConfPersonasnaturales[0].copnNombre1 + ' ' + paciente.tblConfPersonasnaturales[0].copnNombre2 + ' ' + paciente.tblConfPersonasnaturales[0].copnApellido1 + ' ' + paciente.tblConfPersonasnaturales[0].copnApellido2}}

                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">IDENTIFICACION: </label>
                                    {{paciente.tipoidentificacion.comaNombrecorto +' - '+ paciente.copeIdentificacion}}
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">EDAD: </label>
                                    {{ calcularEdad(paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento)}}
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">SEXO: </label>
                                        {{paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrelargo}}
                                    </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">PAIS: </label>
                                    {{paciente.pais.comaNombrelargo}}
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">DEPARTAMENTO: </label>
                                    {{paciente.departamento.comaNombrelargo}}
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">MUNICIPIO: </label>
                                    {{paciente.municipio.comaNombrelargo}}
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">BARRIO: </label>
                                    {{paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio}}
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">DIRECCION: </label>
                                    {{paciente.copeDireccion}}
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="primary--text">TELEFONO: </label>
                                    {{paciente.copeTelefono}}
                                </v-col>
                                <v-col cols="12" sm="12" md="4" v-if="bd.length > 0 ">
                                    <label class="primary--text">EPS: </label>
                                    <v-autocomplete outlined dense v-model="cbdpId" :items="bd" item-text="cocoNombre" item-value="cbdpId" item-color="primary" @change="getServicios" clearable>
                                        <template v-slot:item="data">
                                            <template v-if="(typeof data.item !== 'object')">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{data.item.cocoNombre}}</v-list-item-title>
                                                    <v-list-item-subtitle> {{data.item.coemNombre}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" v-else>
                                    <label class="primary--text">EPS: </label>
                                    <v-autocomplete outlined dense v-model="cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" @change="getServicios" clearable>
                                        <template v-slot:selection="data">
                                            {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="(typeof data.item !== 'object')">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                    <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>
                    <v-row v-if="paciente">
                        <v-col cols="12" sm="12" md="12">
                            <v-btn class="mx-2" tile color="primary" v-if="cbdpId || cocoId" @click="agregarCita">
                                agregar cita
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-row>
                                <v-col cols="12" sm="12" md="8">
                                    <v-form @submit.prevent="agregar">
                                        <v-row v-for="(item,i) in form" :key="i">
                                            <v-col cols="12" sm="12" md="4">
                                                <v-autocomplete outlined dense required v-model="item.citaTipo" :items="tipos" item-text="text" item-value="value" item-color="primary" label="Tipo" clearable @change="getEmpleados(item)">
                                                </v-autocomplete>

                                            </v-col>
                                            <template v-if="item.citaTipo != null">
                                                <v-col cols="12" sm="12" md="8">
                                                    <v-menu v-model="item.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field required outlined dense v-model="item.citaFecha" label="Fecha de la cita" append-icon="event" readonly v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker locale="ES" v-model="item.citaFecha" @input="item.menu = false" @change="(item.citaTipo==0?disponibilidad(item):false)"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" v-if="item.citaTipo == 0">
                                                    <v-autocomplete outlined dense required v-model="item.cocsId" :items="servicios" item-text="tblConfServicios[0].coseNombre" item-value="cocsId" item-color="primary" label="Servicio" clearable @change="getEmpleados(item)">
                                                        <template v-slot:selection="data">
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="data.item.tblConfServicios[0].coseNombre"></v-list-item-title>
                                                                <v-list-item-subtitle class="primary--text" v-html="data.item.tblConfContrato.tblConfPersonasempresas[0].coemNombre"></v-list-item-subtitle>
                                                                <v-list-item-subtitle v-html="data.item.tblConfCup.cocuNombre"></v-list-item-subtitle>
                                                                 <v-list-item-subtitle v-html="data.item.tipo?data.item.tipo.comaNombrelargo:''"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template v-if="(typeof data.item !== 'object')">
                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                            </template>
                                                            <template v-else>
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-html="data.item.tblConfServicios[0].coseNombre"></v-list-item-title>
                                                                    <v-list-item-subtitle class="primary--text" v-html="data.item.tblConfContrato.tblConfPersonasempresas[0].coemNombre"></v-list-item-subtitle>
                                                                    <v-list-item-subtitle v-html="data.item.tblConfCup.cocuNombre"></v-list-item-subtitle>
                                                                    <v-list-item-subtitle v-html="data.item.tipo?data.item.tipo.comaNombrelargo:''"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6" v-if="item.citaTipo == 2">
                                                    <v-autocomplete outlined dense required v-model="item.cocuId" :loading="item.cups.isLoading" :search-input.sync="item.cups.search" hide-no-data hide-selected no-filter :items="item.cups.cups" @keypress.enter="searchCup(item)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Procedimiento" clearable></v-autocomplete>
                                                </v-col>
                                            </template>
                                            <v-col cols="12" sm="12" md="3" >
                                                <v-autocomplete outlined dense required v-model="item.citaTipoDetalle" :items="tipodetalle" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Detalle" clearable></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-autocomplete outlined dense required v-model="item.coprId" :items="item.empleados" :filter="(item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' '+item.tblConfPersonasnaturales[0].copnNombre2 + ' '+item.tblConfPersonasnaturales[0].copnApellido1 + ' '+item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1 " item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" item-color="primary" label="Empleado" @change=" (item.citaTipo==0?disponibilidad(item):false)" clearable>
                                                    <template v-slot:selection="data">
                                                        {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <template v-if="(typeof data.item !== 'object')">
                                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                                        </template>
                                                        <template v-else>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                                <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle v-html="data.item.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].cargo.comaNombrelargo">

                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>

                                            </v-col>
                                             
                                            <!-- <v-col cols="12" sm="12" md="3" v-if="item.coprId && item.citaTipo == 0">
                                                <v-autocomplete outlined dense required v-model="item.citaTipoJornada" :items="tipojornada" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Jornada" @change="disponibilidad(item)" clearable></v-autocomplete>
                                            </v-col> -->
                                            <v-col cols="12" sm="12" md="12" v-if="item.coprId && item.citaFecha && !(item.tipohoras.length > 0 ) && item.citaTipo == 0 ">
                                                <v-alert text prominent dense type="error">
                                                    El medico no tiene horario disponible para esta fecha.
                                                </v-alert>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="4" v-if="item.coprId  && item.citaFecha && /*form.citaTipoJornada &&*/ item.coprId && item.tipohoras.length > 0  && item.citaTipo == 0">
                                                <v-autocomplete dense outlined required v-model="item.citaHora" :items="item.tipohoras" item-text="horaCantidad" item-value="hora" item-color="primary" label="Hora" clearable></v-autocomplete>
                                            </v-col>
                                            <!-- <v-col cols="12" sm="12" md="3" v-if=" item.citaTipoJornada && item.coprId && item.tipoturno.length > 0 && item.citaTipo == 0 ">
                                                <v-autocomplete outlined dense required v-model="item.citaTipoSala" :items="tiposala" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Sala" clearable></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="3" v-if=" item.citaTipoJornada && item.coprId && item.tipoturno.length > 0 && item.citaTipo == 0 ">
                                                <v-autocomplete outlined dense required v-model="item.citaTipoTurno" :items="item.tipoturno" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Turno" clearable></v-autocomplete>
                                            </v-col> -->
                                            <v-col cols="auto" md="1" v-if="i > 0">
                                                <v-btn text icon color="error" @click="eliminarCita(item)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-row class="grey lighten-3">
                                        <v-col cols="12" sm="12" md="12">
                                            <label class="green--text">Ultimas atenciones</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" v-for="(ultimo, i) in ultimas" :key="i">
                                            <label class="primary--text">{{ultimo.coseNombre}}</label> <br>
                                            <label>hace {{new Date().calcularEdad(ultimo.cifaFecha)}}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="red darken-1" text :to="{name:'Admision'}">Cancelar</v-btn>
                    <v-btn color="primary darken-1" :loading="loading" dark @click="agregar" v-if="form.length > 0">Guardar y continuar</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import {
    required
}
from 'vuelidate/lib/validators';
import generales from '../../mixins/generales.js';
export default {
    mixins: [generales],
    data() {
        return {
            url: "cita/citas/",
            paciente: null,
            ultimas: [],
            form: [],
            bd: [],
            cbdpId: null,
            cocoId: null,
            contratos: [],
            tipoestado: [],
            servicios: [],
            tipos: [],
            tipojornada: [],
            tiposala:[],
            form1: [],
            form2: [],
            formOtros: [],
            tipodetalle:[]
        }
    },
    validations: {
        form1: {
            $each: {
                citaFecha: {
                    required,
                },
                citaHora:{
                    required,
                },
                citaTipoEstado: {
                    required,
                },

                coppId: {
                    required,
                },

                coprId: {
                    required,
                },

                cocsId: {
                    required,
                },
            }
        },
        form2: {
            $each: {

                citaFecha: {
                    required,
                },

                citaTipoEstado: {
                    required,
                },

                coppId: {
                    required,
                },
                coprId: {
                    required,
                },
                cocuId: {
                    required,
                },
            }
        },
        formOtros: {
            $each: {
                citaFecha: {
                    required,
                },

                citaTipoEstado: {
                    required,
                },

                coppId: {
                    required,
                },
            }
        },
    },
    created: async function () {
        if (this.getPermiso('verAdmisiones')) {
            if (this.$route.params.paciente) {
                this.paciente = this.$route.params.paciente;
                this.ultimas = await this.$apiService.index("sistema/historiasfacturaciones/recomendados?coppId=" + this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId).then(data => data);
                this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
                this.tipojornada = await this.$apiService.index("sistema/maestra/index/TBL_TIPOJORNADA").then(data => data);
                this.tiposala = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSALAS").then(data => data);
                this.tipodetalle = await this.$apiService.index("sistema/maestra/index/TBL_TIPODETACITA").then(data => data);
                if (this.getPermiso("verAdmisionescita")) {
                    this.tipos.push({ text: 'CITA', value: 0 });
                }
                if (this.getPermiso("verAdmisioneslaboratorios")) {
                    this.tipos.push({ text: 'LABORATORIO', value: 1 });
                }
                if (this.getPermiso("verAdmisionesprocedimientos")) {
                    this.tipos.push({ text: 'PROCEDIMIENTO', value: 2 });
                }
                if (this.getPermiso("verAdmisionesordenesservicios")) {
                    this.tipos.push({ text: 'ORDEN DE SERVICIO', value: 3 });
                }

                if (this.$route.params.tipo.indexOf('EVENTO') > -1) {
                    this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
                    this.contratos = this.contratos.filter(e => e.estado.comaNombrecorto == 'ACTIVO' &&  new Date(e.cocoFechainicio.replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.cocoFechafinal.replace(/-+/g, '/')).getTime() >= new Date().getTime());
                } else {
                    this.bd = await this.$apiService.index("cita/basedatospacientes/index/" + this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId + "/" + new Date().yyyymmdd()).then(data => data);

                }
            }

        }

    },
    
    methods: {
        
        async getEmpleados(item) {
            if (item.citaTipo == 0) {
                if (item.cocsId) {
                    let servicio = this.servicios.find(e => e.cocsId == item.cocsId);
                    item.empleados = await this.$apiService.index( `sistema/Personasempleadosservicios/empleados/${servicio.coseId}/${servicio.cupsId}/${servicio.coseTipoServicio}`).then(data => data);
                    item.empleados = item.empleados.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].estado.comaNombrecorto == 'ACTIVO');
                    item.empleados = item.empleados.filter(e => !e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].permisos.some(a => ((new Date(a.cpepFechainicio).getTime() <= new Date().getTime()) && (new Date(a.cpepFechafinal).getTime() >= new Date().getTime() ))));
                }
            } else {
                let cargos = [
                    'GERENTE',
                    'TC SISTEMAS',
                    'ADMISIONISTA',
                    'ING. DE SISTEMAS'
                ];
                item.empleados = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
                item.empleados = item.empleados.filter(e => !cargos.some(a => a == e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].cargo.comaNombrecorto) && e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].estado.comaNombrecorto == 'ACTIVO');
                item.empleados = item.empleados.filter(e => !e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].permisos.some(a => ((new Date(a.cpepFechainicio).getTime() <= new Date().getTime()) && (new Date(a.cpepFechafinal).getTime() >= new Date().getTime() ))));
            }
        },
        async getServicios() {
            if (this.$route.params.tipo == 'EVENTO') {
                let data = await this.$apiService.index("sistema/contratosxservicios/contrato/" + this.cocoId).then(data => data);
                this.servicios = data;
                this.servicios = this.servicios.filter(e =>
                    (e.estado.comaNombrecorto == 'ACTIVO') && (e.tblConfServicios[0].coseSexo == 0 || e.tblConfServicios[0].coseSexo == ((this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
                    ((new Date().getTime() - new Date(this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.tblConfServicios[0].coseEdadinicio

                    &&
                    ((new Date().getTime() - new Date(this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.tblConfServicios[0].coseEdadfinal)

            } else {
                if (this.bd.length > 0) {
                    if (this.bd.find(e => e.cbdpId == this.cbdpId)) {
                        let data = await this.$apiService.index("sistema/contratosxservicios/contrato/" + this.bd.find(e => e.cbdpId == this.cbdpId).cocoId).then(data => data);
                        this.servicios = data;

                        this.servicios = this.servicios.filter(e =>
                        (e.estado.comaNombrecorto == 'ACTIVO') && (e.tblConfServicios[0].coseSexo == 0 || e.tblConfServicios[0].coseSexo == ((this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
                            ((new Date().getTime() - new Date(this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.tblConfServicios[0].coseEdadinicio

                            &&
                            ((new Date().getTime() - new Date(this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.tblConfServicios[0].coseEdadfinal)

                    }
                }
            }

        },
         addMinutes(date, minutes) {
            return new Date(date.getTime() + minutes*60000);
        },
        getTime(hora){
           return (parseInt(hora.split(':')[0]) - ((parseInt(hora.split(':')[0]) > 12) ? 12 : 0)) + ':' + (hora.split(':')[1].length > 1? hora.split(':')[1]: '0'+hora.split(':')[1]) + ((parseInt(hora.split(':')[0]) > 11) ? ' PM' : ' AM')
        },
        generarHoras(horas,fechaIni,fechaFin,interval,cantidadLimite,cantidades){
                let inicial = new Date('2021/01/01 ' + fechaIni);
                let actual = new Date('2021/01/01 ' + fechaIni);
                let final = new Date('2021/01/01 ' + fechaFin);
                let hora =this.getTime(inicial.getHours() +':'+inicial.getMinutes());
                let cantidad = 0;
                if(cantidades.find(e =>e.cita_hora ==hora )) {
                    cantidad = cantidades.find(e =>e.cita_hora ==hora ).cantidad;
                } 
                if(cantidadLimite > cantidad && (cantidadLimite - cantidad) > 0){
                    horas.push({ horaCantidad: hora + ' - turnos disponibles :'+ (cantidadLimite - cantidad), hora:hora});
                }
                while(actual.getTime() <= final.getTime()){
                    hora = this.getTime(actual.getHours() +':'+actual.getMinutes());
                    cantidad = 0;
                    if(cantidades.find(e =>e.cita_hora ==hora )) {
                        cantidad = cantidades.find(e =>e.cita_hora ==hora ).cantidad;
                    } 
                    if(cantidadLimite > cantidad && (cantidadLimite - cantidad) > 0){
                        horas.push({ horaCantidad: hora + ' - turnos disponibles :'+ (cantidadLimite - cantidad), hora:hora});
                    }
                    actual = this.addMinutes(actual,interval);
                }
                return horas;
        },
        async disponibilidad(item) {
            let cantidades =  await this.$apiService.index(`cita/disponibilidad/horas/${item.coprId}/${item.citaFecha }`).then(data => data);
                let dispo = await this.$apiService.index(`cita/disponibilidad/horarios/${item.coprId}/${item.citaFecha }`).then(data => data);
                if (dispo.length > 0) {
                    dispo.sort((a,b)=>  new Date('2021/01/01 ' + a.cpeh_hora_inicio).getTime() - new Date('2021/01/01 ' + b.cpeh_hora_inicio).getTime());
                    item.tipohoras = [];
                    for (const turno of dispo) {
                        item.tipohoras= this.generarHoras(item.tipohoras,turno.cpeh_hora_inicio,turno.cpeh_hora_final,turno.cpeh_turnos_hora,turno.cant_turnos,cantidades);
                    }
                } else {
                    item.tipohoras = [];

                }
            /*if (item.citaTipoJornada && item.citaFecha && item.coprId) {
                let dispo = await this.$apiService.index(`cita/disponibilidad/turnos2/${item.coprId}/${item.citaFecha }/${item.citaTipoJornada}`).then(data => data);
                if (dispo.length > 0) {
                    item.tipoturno = [];
                    for (const turno of dispo) {
                        item.tipoturno.push(turno);
                    }
                } else {
                    item.tipoturno = [];

                }
            }*/

        },
        async validarHoras(item){
            await this.disponibilidad(item);
            return item.tipohoras.some(e => e.hora ==item.citaHora )
        },
        async searchCup(item) {
            item.cups.isLoading = true;
            if (item.cups.search.length > 0 && item.cups.search.toString().replace(/ /g, "") != "") {
                this.$apiService.index("sistema/cups/buscar/" + item.cups.search.toUpperCase()).then(data => {
                    item.cups.isLoading = false;
                    item.cups.cups = data;

                });
            }

        },
        allowedDates: val => new Date(val.replace(/-+/g, '/')).getDay() != 0 && new Date(val.replace(/-+/g, '/')).getDay() != 6,
        currentDate(date) {
            const fecha = date;
            if (this.allowedDates(fecha.yyyymmdd())) {
                return fecha.yyyymmdd()
            } else {
                fecha.setDate(fecha.getDate() + 1);
                return this.currentDate(fecha);

            }
        },
        async agregar() {
            this.loading = true;
            this.form1 = [];
            this.form2 = [];
            this.formOtros = [];
            this.form.forEach((cita) => {
                if (cita.citaTipo == 0) {
                    this.form1.push(cita);
                }
                if (cita.citaTipo == 2) {
                    this.form2.push(cita);
                }
                if (cita.citaTipo == 1 && cita.citaTipo == 3) {
                    this.formOtros.push(cita);
                }
            });
            this.$v.form1.$touch();
            this.$v.form2.$touch();
            this.$v.formOtros.$touch();
            if (!this.$v.form1.$error && !this.$v.form2.$error && !this.$v.formOtros.$error) {
                for (let item of this.form1) {
                    if(!await  this.validarHoras(item)){
                        this.$swal.fire({
                            title: "Turno ya no disponible",
                            text: "por favor escoja otra hora para la cita del "+item.citaFecha + " alas"+item.citaHora,
                            icon: "error"
                        });
                            return true;
                    }
                }
                
                
                try {
                    if (this.getPermiso("crearCitas")) {
                        let data = await this.$apiService.create(this.url + "createRapida", this.form).then(data => data);

                        if (data) {
                            data.forEach((c) => c.facturado = false);
                            this.$router.push({ name: 'Admision factura', params: { paciente: this.paciente, citas: data } });
                        }
                    }

                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
                
            } else {
                this.$swal.fire({
                    title: "Campos faltantes!",
                    text: "por favor revizar los campos",
                    icon: "warning"
                });
            }
            this.loading = false;
        },
        agregarCita() {
            this.form.push({
                citaFecha: new Date().yyyymmdd(),
                createdBy:this.user.id,
                //citaTipoJornada: null,

                //citaTipoTurno: null,
                //citaTipoSala: null,
                citaTipoEstado: this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId,
                citaTipo: 0,
                cbdpId: this.cbdpId,
                coppId: this.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId,

                coprId: null,
                citaTipoDetalle:null,
                cocsId: null,
                cocoId: this.cocoId,
                empleados: [],
                tipohoras: [],
                menu: false,
                cups: {
                    search: null,
                    isLoading: false,
                    cups: []
                },

            });
        },
        eliminarCita(item) {
            this.form = this.form.filter(
                e =>
                this.form.indexOf(e) !=
                this.form.indexOf(item)
            );
        },
    },
}
</script>

<style>

</style>
