<template>
<div>
    <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1" v-if="permisos.find(e=>e == 'verCitas')">
            Citas
            <v-icon>mdi-calendar</v-icon>
        </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1" v-if="permisos.find(e=>e == 'verCitas')">
            <Citas />
        </v-tab-item>
    </v-tabs-items>
</div>
</template>

<script>
import Citas from "./Citas";

export default {
    components: {
        Citas,
    },
    data() {
        return {
            tab: "tab-1",
            permisos: [],
            apiService: null
        }
    },
    created: async function () {
        
        if (this.$cookies.get('user').role == 'PACIENTE') {

            if (this.$route.params.tab) {
                this.tab = this.$route.params.tab;
            } else {
                if (this.permisos.length > 0) {
                    this.tab = "tab-" + (5 - this.permisos.length);
                }
            }
            this.permisos.push('verCitas');
        }

    },

}
</script>

<style>

</style>
