<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <span class="primary--text display-2">Admision rapida</span><br>
        </v-col>
        <v-col cols="auto" md="12">
            <v-card  elevation="0" class="rounded-xl">
                <v-card-title>
                    <span class="black--text headline">buscar paciente</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <template v-if="form.coppId && tipo == 'CAPITADO'">
                            <v-alert text prominent dense type="success" v-if="bd.length > 0">
                                El paciente se encuentra en las base de datos de <template v-for="b in bd"> {{ ','+ b.cocoNombre  }}</template>
                            </v-alert>
                            <v-alert text prominent dense type="error" v-else>
                                El paciente no se encuentra en ninguna base de datos para el mes de {{ meses[ new Date().getMonth()]}}.
                            </v-alert>
                        </template>
                        <v-row justify="center" align="center">
                            <v-col cols="12" sm="12" md="1" v-if="tipo=='EVENTO'">
                                <template v-if="!nuevo">
                                    <v-icon color="success" style="margin-bottom: 20px;margin-left: 50px;" @click="nuevo=!nuevo;vaciarpaciente(null)">mdi-account-plus</v-icon>
                                </template>
                                <template v-else>
                                    <v-icon color="primary" style="margin-bottom: 20px;margin-left: 50px;" @click="nuevo=!nuevo;vaciarpaciente(null)">mdi-account-search</v-icon>
                                </template>
                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <v-autocomplete outlined :error="$v.form.coppId.$error" v-model="form.coppId" :items="pacientes.pacientes" :loading="pacientes.isLoading" :search-input.sync="pacientes.search" @keypress.enter="searchPaciente" @change="vaciarpaciente(null)" no-filter hide-no-data hide-selected item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId" item-color="primary" label="Paciente" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="2">
                                <v-switch style="margin-bottom: 20px;margin-left: 50px;" v-model="tipo" inset true-value="EVENTO" false-value="CAPITADO" :label="tipo"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                    <template v-if="(datosVaciarPaciente  && !guardopaciente && actualizar) || (tipo=='EVENTO' && datosVaciarPaciente  && !guardopaciente && nuevo)">
                        <Createpaciente v-on:validar="vaciarpaciente($event)" v-bind:datosVaciarPaciente="datosVaciarPaciente"  />
                    </template>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row class="grey lighten-3" v-if="pacienteactual">
                            <v-col cols="12" sm="12" md="3">
                                <v-icon color="primary" style="font-size:200px;">mdi-account-check</v-icon>
                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <v-row>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">NOMBRE: </label>
                                        {{pacienteactual.tblConfPersonasnaturales[0].copnNombre1 + ' ' + pacienteactual.tblConfPersonasnaturales[0].copnNombre2 + ' ' + pacienteactual.tblConfPersonasnaturales[0].copnApellido1 + ' ' + pacienteactual.tblConfPersonasnaturales[0].copnApellido2}}

                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">IDENTIFICACION: </label>
                                        {{pacienteactual.tipoidentificacion.comaNombrecorto +' - '+ pacienteactual.copeIdentificacion}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">EDAD: </label>
                                        {{ new Date().calcularEdad(pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento)}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">SEXO: </label>
                                        {{pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrelargo}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">PAIS: </label>
                                        {{pacienteactual.pais.comaNombrelargo}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">DEPARTAMENTO: </label>
                                        {{pacienteactual.departamento.comaNombrelargo}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">MUNICIPIO: </label>
                                        {{pacienteactual.municipio.comaNombrelargo}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">BARRIO: </label>
                                        {{pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">DIRECCION: </label>
                                        {{pacienteactual.copeDireccion}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="primary--text">TELEFONO: </label>
                                        {{pacienteactual.copeTelefono}}
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-btn color="primary" @click="actualizar = !actualizar ">Actualizar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" dark :to="{name:'Admision cita',params:{tipo:tipo ,paciente:pacienteactual}}" v-if="(tipo== 'EVENTO' || bd.length > 0) && form.coppId">Admisionar</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    required
}
from 'vuelidate/lib/validators';
export default {
    components: {
        Createpaciente: () => import("../sistema/pacientes/Create"),
    },
    data() {
        return {
            actualizar:false,
            guardopaciente: false,
            pacienteactual: null,
            nuevo: false,
            tipo:  this.$cookies.get("DEFAULT"),
            meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
            bd: [],
            url: "cita/citas/",
            user: this.$cookies.get("user"),
            datosVaciarPaciente :null,
            cambio: null,
            pacientes: {
                search: null,
                isLoading: false,
                pacientes: []
            },
            form: {
                coppId: null,
            }
        }
    },
    validations: {
        form: {
            coppId: {
                required,
            },
        },
    },
    created: async function () {
       
    },
    methods: {
         
        async cargarEps() {
            this.$apiService.index("cita/basedatospacientes/index/" + this.form.coppId + "/" + new Date().yyyymmdd()).then(data => {
                if (data.length > 0) {
                    this.bd = data;
                } else {
                    this.bd = [];
                }

            });
        },
        async vaciarpaciente(respuesta) {
            
            if (respuesta == null) {
                this.cambio = null;
                this.datosVaciarPaciente = null;
                this.$nextTick().then(() => {
                    if (this.form.coppId && !this.nuevo) {
                        this.datosVaciarPaciente = {};
                        this.cambio = this.pacientes.pacientes.find(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId).copeId;
                        this.pacienteactual = this.pacientes.pacientes.find(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId);
                        this.datosVaciarPaciente.copeId =this.cambio;
                        this.datosVaciarPaciente.nuevo = this.nuevo;
                        this.guardopaciente = false;
                        // console.log(((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000));
                    }else if(this.nuevo){
                        this.datosVaciarPaciente = {};
                        this.datosVaciarPaciente.copeId =this.cambio;
                        this.datosVaciarPaciente.nuevo = this.nuevo;
                        this.guardopaciente = false;
                    }
                });
            } else {
                if (respuesta.tipo == 'nuevo') {
                    this.pacientes.pacientes.push(respuesta.paciente);
                    this.cambio = respuesta.paciente.copeId;
                    this.form.coppId = respuesta.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId;
                    this.pacienteactual = respuesta.paciente;
                    this.guardopaciente = true;
                    this.datosVaciarPaciente = null;
                    this.actualizar = false;
                } else {
                    this.guardopaciente = true;
                    this.datosVaciarPaciente = null;
                    this.cambio = null;
                    this.actualizar = false;
                    this.$nextTick().then(() => {
                        if (this.form.coppId) {
                            this.cambio = this.pacientes.pacientes.find(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId).copeId;
                            this.pacienteactual = this.pacientes.pacientes.find(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId);
                            this.datosVaciarPaciente.copeId =this.cambio;
                            this.datosVaciarPaciente.nuevo = this.nuevo;
                            // console.log(((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000));
                        }
                    });
                }
            }

            if (this.form.coppId) {
                await this.cargarEps();
                // await this.getServicios();
            }
        },
        async searchPaciente() {
            this.pacientes.isLoading = true;
            if (this.pacientes.search.length > 0 && this.pacientes.search.toString().replace(/ /g, "") != "") {
                this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.pacientes.search.toUpperCase()).then(data => {
                    this.pacientes.isLoading = false;
                    this.pacientes.pacientes = data;
                    this.guardopaciente = false;
                });
            }
        },
    }
}
</script>

<style>

</style>
